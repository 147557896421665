import React, { useState, useEffect } from "react"
import {navigate} from "gatsby-link";
import view from "./ribbon.module.scss"
import { captureEvent } from 'event-service/modules/capture';

export default function DealerRibbon(props) {
  const { dealerAddress, dealerName, serviceHours, salesHours } = props.data
  const { salesNumber, serviceNumber } = props.data

  const [visible, toggleVisible] = useState(false)
  const dealerInfoGoogleTranslate = props.data.googleTranslate
  const GoogleTranslate = () => {

    const googleTranslateElementInit = () => {
      var widths = [0, 768];

          function resizeFn() {
          if (window.innerWidth>=widths[0] && window.innerWidth<widths[1]) {
            new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement }, 'google_translate_element')
          } else {
            new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement }, 'google_translate_element')
          }
          }
          window.onresize = resizeFn;
          resizeFn();
       }
    
       useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
        const gtButton = document.querySelector('#google_translate_element');
        document.body.addEventListener( 'click', function ( event ) {
          if (gtButton.contains(event.target)) {
            event = event || window.event;
            event.preventDefault();
          };
        } );      
      }, []);
    
    return (
      <>
       <div id="google_translate_element"></div>
      </>
    );
    
    } 
  

  useEffect(() => {
    if (window.innerWidth < 1200) {
      visible && (document.body.style.overflow = 'hidden')
      return () => visible && (document.body.style.overflow = 'auto')
    }
  }, [visible]);

  const renderHours = (hours) => (
    hours.map((el) => (
      <div className={view["hour-cont"]}>
        <span>{el.day}</span>
        <span>{el.time}</span>
      </div>
    ))
  )

  const renderMenu = () => (
    <div className={view["ribbon-menu"]}>
      <div className={view["ribbon-head"]} >
        <h1 className={view["ribbon-title"]}>
          Hours & Directions
        </h1>
        <h1 className={view["ribbon-close"]}
          onClick={() => { toggleVisible(false) }}>
          &#215;
        </h1>
      </div>
      <div className={view["ribbon-cont"]}>
        <div className={view["hour-cols"]}>
          <h1>Sales</h1>
          {renderHours(salesHours)}
        </div>
        <div className={view["hour-cols"]}>
          <h1>Service</h1>
          {renderHours(serviceHours)}
        </div>
        <div className={view["map-col"]}>
          <h1>{dealerAddress}</h1>
          {
            dealerName.length > 0 && (
              <iframe
                src={`https://www.google.com/maps?q=${escape(dealerAddress)}&hl=en-US&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp&output=embed`}
                className={view["embed-map"]}
                allowfullscreen=""
                loading={"lazy"}
              />
            )
          }
          <a
            href={`http://maps.google.com/?q=${escape(dealerAddress)}`}
            target="_blank"
            className={view["link-map"]}>
            Open in Google Maps
          </a>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className={view["ribbon"]}>
        <div className={view["ribbon-cap"]}>
          <a href={`tel:${salesNumber}`} data-department="sales">Sales - {salesNumber}</a>
          <a href={`tel:${serviceNumber}`} data-department="service">Service - {serviceNumber}</a>
        </div>
        <div className={view["resp-call"]} onClick={() => {window.location.replace("tel:" + salesNumber); captureEvent({ 'event': 'asc_click_to_call', 'comm_phone_number': `${salesNumber}`, 'department': 'sales'}, { handlers: ['ASC'] }).then(response => console.log(response)).catch(error => console.log(error))}}>
          {/*<p className={view["telephone"]}>&#128381;</p>*/}
          <img style={{"height": "15px"}} src={"https://media.dealermasters.com/phone_icon.png"} alt="phone icon"/>
          <a className={view["tel-capt"]}>
            Call us
          </a>
        </div>
        {dealerInfoGoogleTranslate && <div className={view["translate-widget"]} id="google_translate_element" >{GoogleTranslate()}</div>}
        <div className={`
          ${view["ribbon-btn"]}
          ${!visible && " hours-and-directions-open"}
        `}
          onClick={() => toggleVisible(!visible)}>
          <p>Hours & Directions</p>
          <p className={`
            ${view["ribbon-caret"]}
            ${visible && view["ribbon-rotate"]}
          `}>
            &#709;
          </p>
        </div>
      </div>
      { visible && renderMenu()}
    </>
  )
}

DealerRibbon.defaultProps = {
  data: {
    dealerAddress: "7321 Firestone Blvd, Downey CA 90241",
    dealerName: "Nissan Downey",
    salesHours: [
      { day: "Monday", time: "9:00 AM - 9:00 PM" },
      { day: "Tuesday", time: "9:00 AM - 9:00 PM" },
      { day: "Wednesday", time: "9:00 AM - 9:00 PM" },
      { day: "Thusrday", time: "9:00 AM - 9:00 PM" },
      { day: "Friday", time: "9:00 AM - 9:00 PM" },
      { day: "Saturday", time: "9:00 AM - 9:00 PM" },
      { day: "Sunday", time: "9:00 AM - 9:00 PM" },
    ],
    partsHours: [
      { day: "Monday", time: "9:00 AM - 9:00 PM" },
      { day: "Tuesday", time: "9:00 AM - 9:00 PM" },
      { day: "Wednesday", time: "9:00 AM - 9:00 PM" },
      { day: "Thusrday", time: "9:00 AM - 9:00 PM" },
      { day: "Friday", time: "9:00 AM - 9:00 PM" },
      { day: "Saturday", time: "9:00 AM - 9:00 PM" },
      { day: "Sunday", time: "9:00 AM - 9:00 PM" },
    ]
  }
}
