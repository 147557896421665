import React, { useState } from "react"
import view from "./searchbox.module.scss"

export default function SearchBox() {
  const [visible, setVisible] = useState(false)
  const [query, setQuery] = useState("")

  const showOrFind = () => {
    !visible && setVisible(true)
    if (visible && query.length > 1) {
      window.open(`/search/?search=${escape(query)}`, "_self")
    }
  }

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      window.open(`/search/?search=${escape(query)}`, "_self")
    }
  }

  return (
    <div className={`${view["search-box"]} ${visible && view["search-show"]}`}>
      <div className={view["search-input"]}>
        <span onClick={() => setVisible(false)}>&#215;</span>
        <input type="text" id="search"
          value={query} onChange={handleChange}
          placeholder="Type here to search" onKeyDown={keyPress} />
      </div>
      <div className={view["search-icon"]}
        onClick={showOrFind}>
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2302 16.652C18.6941 14.9414 19.5793 12.7183 19.5793 10.2897C19.5793 4.88298 15.1963 0.5 9.78967 0.5C4.38298 0.5 0 4.88298 0 10.2897C0 15.6963 4.38298 20.0793 9.78967 20.0793C11.8537 20.0793 13.7702 19.4397 15.3493 18.3484L21.501 24.5L23.2896 22.7114L17.2302 16.652ZM9.78967 3.02952C5.78 3.02952 2.52952 6.28 2.52952 10.2897C2.52952 14.2993 5.78 17.5498 9.78967 17.5498C13.7993 17.5498 17.0498 14.2993 17.0498 10.2897C17.0498 6.28 13.7993 3.02952 9.78967 3.02952Z" fill="#343434" />
        </svg>
      </div>
    </div>
  )
}